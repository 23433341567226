import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { classicRestClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { useColumnDefinitions } from 'src/components/admin-table/hooks';
import { withNotifications } from 'src/components/notification-container/notification-container';
import { defaultAccessoriesBarSpacing, defaultPageTitleTransKeys } from 'src/components/organisations/constants';
import { useViewingContext } from 'src/components/organisations/organisation-profile/hooks';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { GET_ORG_COACHES } from 'src/queries/REST/organisations';
import { ClassicQueryFilter, Coach, VenueContactStatus, VenueRoleSubCategory } from 'src/types/classic';
import { GetOrganisationCoaches } from 'src/types/classic-queries';
import { EQUALITY_OPERATOR, SORT_DIRECTION } from 'src/utils/constants/classic';
import { mapToClassicSorts } from 'src/utils/helper/sortOptions';

import {
  createConfigs,
  defaultColumnsConfig,
  defaultFiltersConfig,
  defaultQueryError,
  MIN_SEARCH_CHARS,
  tableId,
} from './config';
import { CoachesProps } from './types';

type TableItem = Coach & { id: string };

const useQFT = initQueryFilterTableHook<GetOrganisationCoaches, TableItem>(GET_ORG_COACHES);

const Coaches = ({
  allowedRoleSubCategories = [VenueRoleSubCategory.COACHING_TEAM],
  columnsConfig = defaultColumnsConfig,
  filtersConfig = defaultFiltersConfig,
  transKeyTitle = defaultPageTitleTransKeys.COACHES,
  transKeyQueryError = defaultQueryError,
}: CoachesProps) => {
  const { t } = useTranslation();
  const selectedFacilityId = useOrgId();
  const { viewingContext } = useViewingContext() ?? {};
  const columns = useColumnDefinitions(columnsConfig);
  const { filters, urlParams } = useMemo(
    () => createConfigs({ t, viewingContext, filtersConfig, selectedFacilityId }),
    [t, viewingContext, filtersConfig, selectedFacilityId],
  );

  const {
    components: { FilterBar, AccessoryBar, AdminTable, props },
    query: { error },
  } = useQFT(
    filters,
    {
      columns,
      accessoryBar: {
        items: [
          { type: 'pagination-info-top', position: 'left', props: {} },
          {
            type: 'column-toggle',
            position: 'right',
            props: {},
          },
        ],
        gap: 12,
        spacing: defaultAccessoriesBarSpacing,
      },
      tableProps: {
        tableId,
        hideTopPaginationInfo: true,
        rowsPerPageOptions: [25, 50],
      },
    },
    {
      urlParams,
      columnSelectorId: tableId,
      mapFiltersToQueryOptions: (ctx) => ({
        client: classicRestClient,
        getTotalItems: (data) => data?.organisationCoaches?.TotalItems ?? 0,
        transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
          const { search } = ctx.filters as { search: string; region: string };
          const { limit, offset, sorts } = vars;
          const defaultSorting = [{ Property: 'FirstName', SortDirection: SORT_DIRECTION.ASCENDING }];
          const queryFilters: ClassicQueryFilter[] = [
            {
              Property: 'Status',
              Values: [VenueContactStatus.ACTIVE],
              Operator: EQUALITY_OPERATOR.EQUALS,
            },
          ];

          if (allowedRoleSubCategories?.length)
            queryFilters.push({
              Property: 'ContactRoles.VenueRole.SubCategory',
              Values: allowedRoleSubCategories,
              Operator: EQUALITY_OPERATOR.EQUALS,
            });

          if (typeof search === 'string' && search.length >= MIN_SEARCH_CHARS)
            queryFilters.push({
              Property: 'FirstName',
              Values: [search],
              Operator: EQUALITY_OPERATOR.CONTAINS,
            });

          return {
            input: {
              QueryParametersPaged: {
                Limit: limit,
                Offset: offset,
                Sorts: sorts ? mapToClassicSorts(sorts) : defaultSorting,
                Filters: queryFilters,
              },
            },
          };
        },
        tableId,
        defaultFetchLimit: 25,
        fetchPolicy: 'no-cache',
      }),
      mapDataToTable: (data) => data?.organisationCoaches?.Items ?? [],
      depsFilterConfig: [filters],
      depsTableConfig: [columns],
    },
  );

  return (
    <PageMaxWidth>
      <Grid>
        <PageHeader title={t(transKeyTitle)} />
      </Grid>

      <Panel extendedPadding>
        <FilterBar {...props.filterBar} withClearBtn />
        <AccessoryBar {...props.accessoryBar} />
        <AdminTable {...props.adminTable} error={error ? t(transKeyQueryError) : undefined} />
      </Panel>
    </PageMaxWidth>
  );
};

export default withNotifications(Coaches);
