import React, { useMemo } from 'react';

import { getClientProps } from 'src/components/coaches/client-props';
import Coaches from 'src/components/coaches/coaches';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const CoachesPage: React.FC = () => {
  const clientProps = useMemo(() => getClientProps()?.coachesPage ?? {}, []);
  return (
    <Layout>
      <SEO title="Coaches" />
      <PrivateRoute>
        <Coaches {...clientProps} />
      </PrivateRoute>
    </Layout>
  );
};

export default CoachesPage;
