import { ColumnDefinition } from 'src/components/admin-table/hooks';
import { TypedFilterObj } from 'src/components/filter-bar/types';
import { defaultFilterSpacing } from 'src/components/organisations/constants';
import { ViewingContext } from 'src/components/organisations/organisation-profile/config';
import { TypedUrlParamConfig } from 'src/hooks/use-query-filter-table';
import { TABLE_IDS } from 'src/utils/constants/tables';
import { buildFiltersConfig, insertRegionFilter } from 'src/utils/helper/filtersConfigUtils';

import { CreateConfigOptions, FiltersConfig } from './types';

export const defaultQueryError = 'coachesModule.queryErrors.getAllCoaches';

export const defaultColumnsConfig: ColumnDefinition[] = [
  {
    key: 'Name',
    header: 'coach name',
    transformer: {
      type: 'nameAndEmail',
      args: {
        accessor: 'FirstName',
        accessorLastName: 'LastName',
        urlTemplate: '{{ID}}',
      },
    },
    sort: false,
    columnToggle: { checked: true },
  },
  {
    key: 'EmailAddress',
    header: 'email address',
    transformer: {
      type: 'email',
    },
    sort: false,
    columnToggle: { checked: true },
  },
  {
    key: 'Roles',
    header: 'roles',
    transformer: {
      type: 'list',
      args: {
        accessor: 'ContactRoles',
        accessorProperty: 'Name',
      },
    },
    sort: false,
    columnToggle: { checked: true },
  },
  {
    key: 'status',
    header: 'status',
    transformer: {
      type: 'coachRegistrationStatus',
      args: {
        accessor: 'Registered',
        accessorInvited: 'Invited',
      },
    },
    sort: false,
    columnToggle: { checked: true },
  },
];
export const defaultFiltersConfig: FiltersConfig = {
  qualificationStatus: [],
  region: { transKeyDefaultOption: 'any county' },
};

export const MIN_SEARCH_CHARS = 3;
export const tableId = TABLE_IDS.COACHES_LIST;

const createFilters = ({ t, viewingContext, filtersConfig, selectedFacilityId }: CreateConfigOptions) => {
  const filters: (TypedFilterObj & { initialValue: any })[] = [
    {
      key: 'search',
      type: 'search',
      initialValue: '',
      props: {
        id: 'coaches-search-input',
        placeholder: t('coachesModule.filters.searchPlaceholderAllCoaches'),
        minCharsTooltip: { minChars: MIN_SEARCH_CHARS },
      },
      spacing: defaultFilterSpacing,
    },
  ];

  if (filtersConfig?.qualificationStatus) {
    filtersConfig.qualificationStatus.forEach(({ key, transKeyDefaultLabel }) =>
      filters.push({
        key,
        type: 'select:coach-qualification-status',
        initialValue: '',
        props: {
          id: key,
          defaultOptionLabel: t(transKeyDefaultLabel),
        },
        spacing: defaultFilterSpacing,
      }),
    );
  }

  if (filtersConfig?.region) {
    const { insertAtIndex = 1, transKeyDefaultOption, visibility } = filtersConfig.region;
    insertRegionFilter(filters, {
      defaultOptionLabel: t(transKeyDefaultOption),
      selectedFacilityId,
      insertAtIndex,
      viewingContext,
      visibility,
    });
  }

  return buildFiltersConfig(filters);
};

const createUrlParams = ({ viewingContext, filtersConfig }: CreateConfigOptions) => {
  const paramsConfig: {
    [key: string]: TypedUrlParamConfig<string>;
  } = {
    search: {
      type: 'STRING',
    },
  };

  if (
    filtersConfig?.region &&
    (!filtersConfig.region?.visibility || filtersConfig.region.visibility.includes(viewingContext as ViewingContext))
  ) {
    paramsConfig['region'] = {
      type: 'STRING',
    };
  }

  return paramsConfig;
};

export const createConfigs = (options: CreateConfigOptions) => {
  return {
    filters: createFilters(options),
    urlParams: createUrlParams(options),
  };
};
